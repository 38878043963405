<template>
  <div class="ps-lg-7 pt-4">
    <h1 class="fs-21 fw-700 mb-5 text-vi">{{ $t('profile') }}</h1>
    <v-row style="background: #fff">
      <v-col xl="8" style="padding: 0">
        <v-card elevation="0" class="mb-6">
          <v-card-title
            class="fs-16 fw-700 white--text"
            style="background: #95acff; padding: 10px"
          >
            {{ $t('profile_information') }}
          </v-card-title>
          <div style="padding: 0 20px">
            <v-form
              class="py-4"
              lazy-validation
              @submit.prevent="updateBasic()"
              enctype="multipart/form-data"
            >
              <input
                type="email"
                name="hidden"
                style="width: 0; height: 0; border: 0; padding: 0; background: "
              />
              <input
                type="password"
                name="hidden"
                style="width: 0; height: 0; border: 0; padding: 0"
              />

              <label class="text-primary">{{ $t('full_name') }}</label>
              <v-text-field
                background-color="transparent"
                :placeholder="$t('full_name')"
                v-model="form.name"
                :error-messages="nameErrors"
                outlined
                hide-details="auto"
                class="mb-3"
              ></v-text-field>

              <label class="text-primary">{{ $t('email') }}</label>
              <div class="position-relative">
                <v-text-field
                  background-color="transparent"
                  :placeholder="$t('email_address')"
                  v-model="form.email"
                  outlined
                  hide-details="auto"
                  class="mb-3"
                  disabled
                ></v-text-field>
                <div
                  class="absolute-top-right h-45px fs-14 update-email text-primary"
                  @click="updateAccount('email')"
                >
                  {{ $t('update_email') }}
                </div>
              </div>

              <label class="text-primary">{{ $t('phone_number') }}</label>
              <div class="position-relative" style="z-index: 10000">
                <vue-tel-input
                  v-model="form.phone"
                  v-bind="mobileInputProps"
                  :preferred-countries="availableCountries"
                  :auto-format="false"
                  class="mb-3 opacity-80"
                  @validate="phoneValidate"
                  @country-changed="onCountryChanged"
                  :disabled="phoneDisabled || !form.phone"
                >
                  <template slot="arrow-icon">
                    <span style="margin: 0 3px">
                      {{ dialCode ? `+${dialCode}` : '' }}
                    </span>
                    <span class="vti__dropdown-arrow">&nbsp;▼</span>
                  </template>
                </vue-tel-input>
                <div
                  class="absolute-top-right h-45px fs-14 update-email text-primary"
                  @click="updateAccount('phone')"
                >
                  {{ $t('update_phone') }}
                </div>
              </div>

              <label class="text-primary">{{ $t('old_password') }}</label>
              <v-text-field
                background-color="transparent"
                placeholder="******"
                type="password"
                v-model="form.oldPassword"
                :error-messages="oldPasswordErrors"
                outlined
                hide-details="auto"
                class="mb-3"
              ></v-text-field>

              <label class="text-primary">{{ $t('new_password') }}</label>
              <v-text-field
                background-color="transparent"
                placeholder="******"
                type="password"
                v-model="form.password"
                :error-messages="passwordErrors"
                @blur="$v.form.password.$touch()"
                outlined
                hide-details="auto"
                class="mb-3"
              ></v-text-field>

              <label class="text-primary">{{ $t('confirm_password') }}</label>
              <v-text-field
                background-color="transparent"
                placeholder="******"
                type="password"
                v-model="form.confirmPassword"
                :error-messages="confirmPasswordErrors"
                @blur="$v.form.confirmPassword.$touch()"
                outlined
                hide-details="auto"
                class="mb-3"
              ></v-text-field>

              <label class="text-primary">{{ $t('profile_image') }}</label>
              <div
                class="d-flex flex-column align-center justify-center border rounded pa-6 mb-3"
                style="background: #fff"
              >
                <div class="avatar-upload">
                  <v-avatar size="160" class="">
                    <img
                      :src="
                        isSelectImage
                          ? form.previewAvatar
                          : addResourceImage(form.previewAvatar)
                      "
                      @error="imageFallback($event)"
                    />
                  </v-avatar>
                  <label>
                    <input
                      type="file"
                      id="avatar-input"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="previewThumbnail"
                    />
                    <i class="las la-image"></i>
                  </label>
                </div>
                <label
                  for="avatar-input"
                  class="border border-gray-300 fs-14 fw-500 grey lighten-4 mt-3 opacity-50 px-4 py-1 rounded c-pointer text-primary"
                >
                  {{ $t('select_image') }}
                </label>
              </div>
              <v-btn
                type="submit"
                :loading="infoUpdateLoading"
                :disabled="infoUpdateLoading"
                color="primary"
                elevation="0"
                class="px-10"
                @click="updateBasic"
              >
                {{ $t('update') }}
              </v-btn>
            </v-form>
          </div>
        </v-card>
        <v-card
          elevation="0"
          style="background: #fff !important; padding: 14px"
          class="mb-6"
        >
          <address-dialog
            style="z-index: 10001"
            :show="addDialogShow"
            @close="addressDialogClosed"
            :old-address="addressSelectedForEdit"
          />
          <v-card-title class="pa-0 fs-16 fw-700 mb-4">
            <span class="text-primary">{{ $t('addresses') }}</span>
            <v-btn
              color="primary"
              elevation="0"
              class="ms-auto"
              @click.stop="addDialogShow = true"
            >
              {{ $t('add_new') }}
            </v-btn>
          </v-card-title>
          <v-row class="mb-4 row-cols-1 row-cols-sm-2 gutters-10">
            <v-col>
              <div class="fs-13 fw-600 opacity-80 mb-2 text-primary">
                {{ $t('default_shipping_address') }}
              </div>
              <div
                class="border rounded pa-4 fs-13"
                v-if="getDefaultShippingAddress"
              >
                <div>{{ getDefaultShippingAddress.address }}</div>
                <div>
                  {{ getDefaultShippingAddress.postal_code }},
                  {{ getDefaultShippingAddress.city?.name }},
                  {{ getDefaultShippingAddress.state?.name }}
                </div>
                <div>{{ getDefaultShippingAddress.country?.name }}</div>
                <div>{{ getDefaultShippingAddress.phone }}</div>
              </div>
            </v-col>
            <!-- <v-col>
                <div class="fs-13 fw-600 opacity-80 mb-2">
                  {{ $t('default_billing_address') }}
                </div>
                <div
                  class="border rounded pa-4 fs-13"
                  v-if="getDefaultBillingAddress"
                >
                  <div>{{ getDefaultBillingAddress.address }}</div>
                  <div>
                    {{ getDefaultBillingAddress.postal_code }},
                    {{ getDefaultBillingAddress.city }},
                    {{ getDefaultBillingAddress.state }}
                  </div>
                  <div>{{ getDefaultBillingAddress.country }}</div>
                  <div>{{ getDefaultBillingAddress.phone }}</div>
                </div>
              </v-col> -->
          </v-row>
        </v-card>
        <v-card
          elevation="0"
          style="background: #fff !important; padding: 14px"
          class="mb-6"
        >
          <v-card-title class="pa-0 fs-16 fw-700 mb-4 text-primary">
            {{ $t('all_addresses') }}
          </v-card-title>
          <div>
            <v-row
              class="row-cols-1 row-cols-sm-2 gutters-10"
              v-if="addressesLoaded && getAddresses.length > 0"
            >
              <v-col v-for="(address, i) in getAddresses" :key="i">
                <div
                  class="border rounded py-4 ps-4 pe-9 fs-13 position-relative"
                >
                  <div>{{ address.address }}</div>
                  <div>
                    {{ address.postal_code }}, {{ address.city?.name }},
                    {{ address.state?.name }}
                  </div>
                  <div>{{ address.country.name }}</div>
                  <div>{{ address.phone }}</div>
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="absolute-top-right"
                        color="transparent"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        small
                      >
                        <i class="las la-ellipsis-v fs-18"></i>
                      </v-btn>
                    </template>
                    <v-list class="">
                      <v-list-item
                        class="c-pointer"
                        @click="editAddress(address)"
                      >
                        <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        class="c-pointer"
                        @click="markDefaultShipping(address.id)"
                      >
                        <v-list-item-title>
                          {{ $t('mark_as_default_shipping_address') }}
                        </v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item
                          class="c-pointer"
                          @click="markDefaultBilling(address.id)"
                        >
                          <v-list-item-title>
                            {{ $t('mark_as_default_billing_address') }}
                          </v-list-item-title>
                        </v-list-item> -->
                      <v-list-item
                        class="c-pointer"
                        @click="deleteAddress(address.id)"
                      >
                        <v-list-item-title>
                          {{ $t('delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <div class="text-center" v-else>
              <div>{{ $t('no_saved_address') }}</div>
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- <v-col cols="auto"></v-col> -->
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'
import AddressDialog from '../../components/address/AddressDialog.vue'
export default {
  data: () => ({
    isSelectImage: false,
    mobileInputProps: {
      inputOptions: {
        type: 'tel',
        placeholder: 'phone number'
      },
      dropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true
      },
      autoDefaultCountry: false,
      validCharactersOnly: true,
      mode: 'international'
    },
    form: {
      name: '',
      email: '',
      phone: '',
      invalidPhone: false,
      avatar: '',
      previewAvatar: '',
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },
    passwordShow: false,
    addDialogShow: false,
    infoUpdateLoading: false,
    addressSelectedForEdit: {},

    // 国家区号
    dialCode: '',

    // 禁止电话号码输入
    phoneDisabled: false
  }),
  components: {
    VueTelInput,
    AddressDialog
  },
  validations: {
    form: {
      name: { required },
      // email: {
      //     email,
      //     required
      // },
      oldPassword: { required },
      password: { minLength: minLength(6) },
      confirmPassword: { sameAsPassword: sameAs('password') }
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'authSettings']),
    ...mapGetters('app', ['availableCountries']),
    ...mapGetters('address', [
      'getAddresses',
      'addressesLoaded',
      'getDefaultShippingAddress',
      'getDefaultBillingAddress'
    ]),
    nameErrors() {
      const errors = []
      if (!this.$v.form.name.$dirty) return errors
      !this.$v.form.name.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t('this_field_is_required_a_valid_email'))
      return errors
    },
    oldPasswordErrors() {
      const errors = []
      if (!this.$v.form.oldPassword.$dirty) return errors
      !this.$v.form.oldPassword.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.minLength &&
        errors.push(this.$i18n.t('password_must_be_minimum_6_characters'))
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.form.confirmPassword.$dirty) return errors
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$i18n.t('password_and_confirm_password_should_match'))
      return errors
    }
  },
  created() {
    this.form.name = this.currentUser.name
    this.form.email = this.currentUser.email
    this.form.phone = this.currentUser.phone
    this.form.previewAvatar = this.currentUser.avatar

    this.fetchAddresses()
  },
  methods: {
    ...mapMutations('auth', ['setUser', 'setAuthSettingsVerify']),
    ...mapMutations('address', ['setAddresses']),
    ...mapActions('address', ['fetchAddresses']),

    // 电话区号改变
    onCountryChanged({ dialCode }) {
      this.dialCode = dialCode

      if (this.form.phone.indexOf(dialCode) === 1) {
        this.form.phone = this.form.phone.replace(`+${dialCode}`, '')
        this.phoneDisabled = true
      }
    },

    previewThumbnail(event) {
      this.isSelectImage = true
      this.form.avatar = event.target.files[0]
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.form.previewAvatar = e.target.result
        }
        reader.readAsDataURL(event.target.files[0])
      }
    },
    phoneValidate(phone) {
      this.form.invalidPhone = phone.valid ? false : true
    },
    async updateBasic() {
      // if(this.form.email == ""){
      //     this.snack({
      //         message: "Email is required.",
      //         color: "red"
      //     });
      //     return;
      // }
      // if(this.form.email != "" && !this.$v.form.email.email){
      //     this.snack({
      //         message: "Email must be valid.",
      //         color: "red"
      //     });
      //     return;
      // }
      // if(this.form.phone != ""  && this.form.invalidPhone){
      //     this.snack({
      //         message: "Phone number must be valid.",
      //         color: "red"
      //     });
      //     return;
      // }

      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      // this.form.phone = this.form.phone.replace(/\s/g, '')
      this.infoUpdateLoading = true

      const formData = new FormData()
      for (var key in this.form) {
        formData.append(key, this.form[key])
      }

      const res = await this.call_api(
        'post',
        'user/info/update',
        formData,
        true
      )
      if (res.data.success) {
        this.setUser(res.data.user)
        this.snack({ message: res.data.message })
      } else {
        this.snack({ message: res.data.message, color: 'red' })
      }
      this.infoUpdateLoading = false
    },
    async deleteAddress(id) {
      const res = await this.call_api('get', `user/address/delete/${id}`)
      if (res.data.success) {
        this.setAddresses(res.data.data)
        this.snack({ message: res.data.message })
      }
    },
    editAddress(address) {
      this.addressSelectedForEdit = address
      this.addDialogShow = true
    },
    async markDefaultShipping(id) {
      const res = await this.call_api(
        'get',
        `user/address/default-shipping/${id}`
      )
      if (res.data.success) {
        this.setAddresses(res.data.data)
        this.snack({ message: res.data.message })
      } else {
        this.snack({
          message: this.$i18n.t('something_went_wrong'),
          color: 'red'
        })
      }
    },
    async markDefaultBilling(id) {
      const res = await this.call_api(
        'get',
        `user/address/default-billing/${id}`
      )
      if (res.data.success) {
        this.setAddresses(res.data.data)
        this.snack({ message: res.data.message })
      } else {
        this.snack({
          message: this.$i18n.t('something_went_wrong'),
          color: 'red'
        })
      }
    },
    addressDialogClosed() {
      this.addressSelectedForEdit = {}
      this.addDialogShow = false
    },
    updateAccount(type) {
      const params = {
        name: 'UpdateAccount'
      }

      this.setAuthSettingsVerify(type)

      this.$router.push(params)
    }
  }
}
</script>

<style scoped>
.update-email {
  line-height: 44px;
  padding-right: 20px;
  text-decoration: underline;
}
.vue-tel-input {
  background: transparent !important;
}
</style>
